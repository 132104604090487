import thumbnailEp01 from '../images/thumbnails/ep01.webp';
import thumbnailEp02 from '../images/thumbnails/ep02.webp';
import thumbnailEp03_1 from '../images/thumbnails/ep03-1.webp';
import thumbnailEp03_2 from '../images/thumbnails/ep03-2.webp';
import thumbnailEp04 from '../images/thumbnails/ep04.webp';
import thumbnailEp05 from '../images/thumbnails/ep05.webp';
import thumbnailEp06 from '../images/thumbnails/ep06.webp';
import thumbnailEp07 from '../images/thumbnails/ep07.webp';
import thumbnailEp08_1 from '../images/thumbnails/ep08-1.webp';
import thumbnailEp08_2 from '../images/thumbnails/ep08-2.webp';
import thumbnailEp08_3 from '../images/thumbnails/ep08-3.webp';
import thumbnailCreditos from '../images/thumbnails/creditos.webp';

export interface Episode {
  id: string;
  videoUrl: string;
  title: string;
  subtitle: string;
  url: string;
  thumbnail: string;
}

const episodes: Episode[] = [
  {
    id: '985409789',
    videoUrl:
      'https://player.vimeo.com/video/985409789?h=c3d96070a3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    title: 'Episódio 1',
    subtitle: 'Conde Bétula Origens',
    url: '/ep01',
    thumbnail: thumbnailEp01,
  },
  {
    id: '986088689',
    videoUrl:
      'https://player.vimeo.com/video/986088689?h=c3d96070a3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    title: 'Episódio 2',
    subtitle: 'Professor Neto e Professor Frigideira',
    url: '/ep02',
    thumbnail: thumbnailEp02,
  },
  {
    id: '986088915',
    videoUrl:
      'https://player.vimeo.com/video/986088915?h=c3d96070a3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    title: 'Episódio 3.1',
    subtitle: 'Uma Questão de Tempo e de Histórias',
    url: '/ep03-1',
    thumbnail: thumbnailEp03_1,
  },
  {
    id: '986089051',
    videoUrl:
      'https://player.vimeo.com/video/986089051?h=c3d96070a3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    title: 'Episódio 3.2',
    subtitle: 'Uma Questão de Tempo e de Histórias',
    url: '/ep03-2',
    thumbnail: thumbnailEp03_2,
  },
  {
    id: '986089153',
    videoUrl:
      'https://player.vimeo.com/video/986089153?h=c3d96070a3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    title: 'Episódio 4',
    subtitle: 'A Tecnologia Evoluindo no Tempo',
    url: '/ep04',
    thumbnail: thumbnailEp04,
  },
  {
    id: '986089495',
    videoUrl:
      'https://player.vimeo.com/video/986089495?h=c3d96070a3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    title: 'Episódio 5',
    subtitle: 'Por que nosso cérebro não é uma máquina',
    url: '/ep05',
    thumbnail: thumbnailEp05,
  },
  {
    id: '986089653',
    videoUrl:
      'https://player.vimeo.com/video/986089653?h=c3d96070a3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    title: 'Episódio 6',
    subtitle: 'Cão Virtual x Gato Virtual',
    url: '/ep06',
    thumbnail: thumbnailEp06,
  },
  {
    id: '986089763',
    videoUrl:
      'https://player.vimeo.com/video/986089763?h=c3d96070a3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    title: 'Episódio 7',
    subtitle: 'Veja-me Mucho - Porque as telas roubam nossa atenção',
    url: '/ep07',
    thumbnail: thumbnailEp07,
  },
  {
    id: '986089887',
    videoUrl:
      'https://player.vimeo.com/video/986089887?h=c3d96070a3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    title: 'Episódio 8.1',
    subtitle: 'Eu não me mexo muito',
    url: '/ep08-1',
    thumbnail: thumbnailEp08_1,
  },
  {
    id: '986090098',
    videoUrl:
      'https://player.vimeo.com/video/986090098?h=3d40368d56&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    title: 'Episódio 8.2',
    subtitle: 'O Sono é nosso Superpoder',
    url: '/ep08-2',
    thumbnail: thumbnailEp08_2,
  },
  {
    id: '986090197',
    videoUrl:
      'https://player.vimeo.com/video/986090197?h=c3d96070a3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    title: 'Episódio 8.3',
    subtitle: 'Finale',
    url: '/ep08-3',
    thumbnail: thumbnailEp08_3,
  },
  {
    id: '986090443',
    videoUrl:
      'https://player.vimeo.com/video/986090443?h=c3d96070a3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    title: 'Créditos',
    subtitle: '',
    url: '/creditos',
    thumbnail: thumbnailCreditos,
  },
  {
    id: '1026658089',
    videoUrl:
      'https://player.vimeo.com/video/1026658089?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    title: 'Extra',
    subtitle: '',
    url: '/extra',
    thumbnail: thumbnailCreditos,
  },
];

export default episodes;
