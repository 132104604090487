import { createBrowserRouter } from 'react-router-dom';
import Login from '../pages/login';
import TermsOfService from '../pages/terms-of-service';
import Episodes from '../pages/episodes';
import Layout from '../components/Layout';
import episodes from '../data/episodes';

export default createBrowserRouter([
  {
    path: '/',
    errorElement: <Login />,
    children: [
      {
        element: <Login />,
        path: 'login',
      },
      {
        element: <Layout />,
        errorElement: <Layout />,
        path: '/',
        children: [
          ...episodes.map((episode) => ({
            path: episode.url,
            element: <Episodes episode={episode} />,
          })),
        ],
      },
    ],
  },
  {
    element: <TermsOfService />,
    path: '/termos',
  },
]);
