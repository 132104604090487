import React from 'react';
import styles from './terms-of-service.module.scss';

function TermsOfService() {
  return (
    <div className={styles.tos}>
      <h1>Termos de Uso: Aplicativo Uso Saudável das Telas</h1>
      {`
  Estes são os termos de uso simplificados do aplicativo Uso Saudável das Telas, precisamos do seu aceite aos termos para seguirmos com seu cadastro e uso do aplicativo.

Nós iremos coletar informações referentes ao tempo de uso do seu celular para construir as notas que serão dadas de acordo com o uso.
Nós não iremos coletar informações pessoais como nome, telefone, endereço, ou quaisquer outro tipo de informação que possa identificar você como indivíduo.

Todos os dados coletados serão anonimizados e atrelados ao código de ativação do almanaque, garantindo assim sua segurança.
Necessitamos da informação referente a idade do usuário, pois essa irá influenciar na régua de tempo de tela que será usada para dar as notas dos heróis e dos vilões.

Os dados coletados, após anonimizados, poderão ser usados pela Imersa Audiovisual Multimídia Limitada – ME para fins de pesquisa e entendimento do comportamento do usuário do aplicativo, a fim de averiguar os resultados de sua metodologia e melhorar seus produtos de acordo com as informações coletadas.
   `}
    </div>
  );
}

export default TermsOfService;
